@import 'assets/styles/colors.scss';

.input {
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-block: 0;
  padding-inline: 0;
  text-indent: 15px;
  font-family: 'Montserrat Light 300';

  &::placeholder {
    color: $grey;
    font-size: 18px;
    font-family: 'Montserrat Light 300';
  }

  &:focus {
    outline-width: 0;
  }

  &:disabled {
    background: $white;
  }
}