@import 'assets/styles/colors.scss';

.header_cell {
  width: 100%;
  height: 85px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $black;

  &:nth-of-type(3) {
    min-width: 320px;
  }
  &:nth-of-type(2) {
    .input_wrapper {
      justify-content: center;
    }
  }
}

.active_area {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}

.input_wrapper {
  width: 110px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: start;
  flex-direction: row-reverse;

  input {
    width: 30%;
    margin: 0 10px 0 0;
  }
}

.title {
  text-align: center;
  font-family: 'Montserrat SemiBold 600';
}

.item_text {
  font-family: "Montserrat SemiBold 600";
}
