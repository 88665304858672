@import 'assets/styles/colors.scss';

.page {
  color: $black;
  display: flex;
  height: inherit;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 145px;
}

.login_btn {
  width: 520px;
  height: 48px;
  font-size: 16px;
}
