.cancel {
  height: calc(100% - 140px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.icon {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
  }
}

.text {
  font-size: 24px;
  margin-top: 15px;
  font-family: 'Montserrat Regular 400';
}

.button_wrapper {
  margin-top: 20px;
}