@import 'assets/styles/colors.scss';

.table {
  width: 100%;
  border-collapse: collapse;
}

.table_wrapper {
  td {
    text-align: center;
    border: 1px solid $black;

    &:nth-child(2) {
      text-align: left;
    }
    &:nth-child(6) {
      min-width: 130px;
    }

    input[type="datetime-local"] {
      max-width: 100px;
   }
  }
  
  th {
    padding: 8px;
    color: $white;
    text-align: center;
    background-color: $black;
    border: 1px solid $white;

    &:nth-child(2) {
      text-align: left;
    }
  }
}

.button_wrapper {
  margin-top: 30px;
}
