.filters {
  display: flex;
  margin: 15px 0;
  justify-content: end;
}

.filters_item {
  display: flex;
  margin: 0 10px;
  cursor: pointer;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 2px;

  img {
    width: 100%;
    height: 100%;
  }
}
