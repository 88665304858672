.modal_content_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button_wrapper {
  width: 250px;
  display: flex;
  justify-content: space-between;
}