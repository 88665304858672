@import 'assets/styles/colors.scss';

.header_cell {
  width: 100%;
  height: 85px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $black;

  &:nth-of-type(3) {
    width: 120%;
  }
  &:nth-of-type(2) {
    .input_wrapper {
      justify-content: center;
    }
  }
}

.input_wrapper {
  width: 45%;
  display: flex;
  margin: 0 5px;
  align-items: center;
  justify-content: start;
  flex-direction: row-reverse;

  input {
    margin: 0 10px 0 0;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat SemiBold 600';
}
