@import 'assets/styles/colors.scss';

.cards_wrapper {
  display: flex;
  justify-content: space-around;
}

.coupon {
  display: flex;
  margin-top: 100px;
  align-items: center;
}

.input_wrapper {
  width: 390px;
  height: 56px;
  margin-right: 30px;
  border: 1px solid $black;
}

.bottom_section {
  margin-top: 90px;
  text-align: left;
  padding-bottom: 90px;
}

.bottom_section_title {
  font-size: 24px;
  font-family: 'Montserrat SemiBold 600';
}

.bottom_section_text {
  font-size: 14px;
  margin: 10px 0 30px;
  font-family: 'Montserrat Regular 400';
}

.switch_wrapper {
  display: flex;
  margin-top: 95px;
  margin-bottom: 50px;
  justify-content: center;
}
