.active_icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 2px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}