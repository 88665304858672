@import 'assets/styles/colors.scss';

.button {
  height: 40px;
  border: none;
  outline: none;
  display: flex;
  font-size: 14px;
  padding: 0 25px;
  transition: 0.5s;
  min-width: 115px;
  overflow: hidden;
  border-radius: 0;
  width: max-content;
  position: relative;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat Regular 400';

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled.view_primary {
    background-color: $grey;
  }

  &.view_primary {
    color: $white;
    border: 1px solid $black;
    background-color: $black;

    &:not(.disabled):hover {
      color: $black;
      background-color: transparent;
    }
  }

  &.view_secondary {
    color: $black;
    border: 1px solid $black;
    background-color: $transparent;

    &:hover {
      color: $white;
      background-color: $black;
    }
  }

  &.view_danger {
    color: $white;
    border: 1px solid $red;
    background-color: $red;

    &:hover {
      opacity: .5;
    }
  }
}

.logo_wrapper {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.logo {
  width: 100%;
  height: 100%;
}
