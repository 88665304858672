@import 'assets/styles/colors.scss';

table {
  width: 100%;
  border-collapse: collapse;
}

.table_wrapper {
  td {
    text-align: center;
    border: 1px solid $black;

    .inactive {
      color: $white;
      padding: 3px 5px;
      background: $red;
      font-family: 'Montserrat SemiBold 600';
    }
  
    .active {
      color: $white;
      padding: 3px 12px;
      background: $seaGreen;
      font-family: 'Montserrat SemiBold 600';
    }

    &:nth-child(2) {
      text-align: left;
    }
  }
  
  th {
    padding: 8px;
    color: $white;
    text-align: center;
    background-color: $black;
    border: 1px solid $white;

    &:nth-child(2) {
      text-align: left;
    }
  }
}

.button_wrapper {
  margin-top: 30px;
}
