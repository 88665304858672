@import 'assets/styles/colors.scss';

body {
  height: 100%;
  margin: 0;
  font-family: 'Montserrat Light 300';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, #root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}

.container {
  height: 100%;
  width: 1440px;
  padding: 0 20px;
}

.dropdown {
  &> div:first-of-type {
    border: 0;
    outline: none;
  }
}

.react-switch-bg {
  height: 27px !important;
}

@font-face {
  font-family: 'Montserrat Light 300';
  src: local('Montserrat Light'), url('~assets/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Regular 400';
  src: local('Montserrat Regular'), url('~assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold 600';
  src: local('Montserrat SemiBold'), url('~assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold 700';
  src: local('Montserrat Bold'), url('~assets/fonts/Montserrat-Bold.ttf') format('truetype');
}


.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: $seaGreen;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: $red;
}