@import 'assets/styles/colors.scss';

.table {
  display: flex;
  position: relative;
}

.title {
  padding: 10px;
  color: $white;
  background-color: $black;
  border: 1px solid $white;
}

.active_column {
  display: flex;
  flex-direction: column;

  input {
    color: $white;
    background: $black;
  }
}

.cell {
  padding: 10px;
  min-width: 170px;
  border: 1px solid $black;

  input {
    color: $black;
    background: $white;
  }
}

.button_wrapper {
  margin-top: 30px;
}

.add_wrapper {
  margin-left: 40px;
  display: flex;
  width: 150px;
  cursor: pointer;
  height: 20px;
}

.icon_img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.add_text {
  width: 100%;
}

.remove_wrapper {
  text-align: center;
  height: 20px;
  margin-bottom: 5px;
}

.add_row_wrapper {
  display: flex;
  margin-top: 20px;
}

.class_name_wrapper {
  display: flex;
  position: relative;
}

.remove_row_wrapper {
  position: absolute;
  left: -35px;
  top: 10px;
}