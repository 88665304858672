@import 'assets/styles/colors.scss';

.footer {
  display: flex;
}

.date_wrapper {
  display: flex;
  justify-content: space-evenly;

  input {
    width: 100px;
  }
}