.header {
  display: flex;
  padding-top: 25px;
  align-items: center;
  justify-content: space-between;
}

.logo_link {
  width: 190px;
  height: 90px;
}

.logo {
  width: 100%;
  height: 100%;
}

.right_section {
  display: flex;
  align-items: center;
}

.username {
  margin-right: 20px;
}