@import 'assets/styles/colors.scss';

.page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text {
  font-size: 21px;
  margin-bottom: 40px;
}