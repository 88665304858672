@import 'assets/styles/colors.scss';

.footer_cell {
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $black;

  input {
    text-align: center;
  }

  &:nth-of-type(3) {
    width: 120%;

    .title {
      left: 50%;
      top: auto;
      position: absolute;
      transform: translate(-50%); 
    }
  }
  &:nth-of-type(4) {
    input {
      width: 45%;
    }
  }
}
