@import 'assets/styles/colors.scss';

.card {
  width: 295px;
  border: 1px solid $black;
  border-width: 1px 0 0 0;

  &.disabled {
    .title {
      background-color: grey;
    }
  }
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 33px;
  color: $white;
  background-color: $black;
  border: 1px solid $black;
  border-width: 0 1px 1px 1px;
  padding: 12px 0 12px 10px;
  font-family: 'Montserrat SemiBold 600';
}

.plan_item {
  display: flex;
  font-size: 22px;
  align-items: center;
  border: 1px solid $black;
  padding: 20px 0 20px 10px;
  border-width: 0 1px 1px 1px;
}

.button_wrapper {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}